<template>
  <div class="container">
    <h1 style="text-align:center;">טופס תיקוני שעות</h1>
    <div class="warpper">
      <div class="field">
        <p>שם מלא של העובד</p>
        <InputText
          v-model="values.fullName"
          type="text"
          placeholder="שם מלא"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>מס' ת"ז</p>
        <InputText
          v-model="values.idNumber"
          type="text"
          placeholder="מס' זהות"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>שם פותח/ת הבקשה</p>
        <InputText
          v-model="values.ownerName"
          type="text"
          placeholder="הזן/י את שמך"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>דוא"ל לעדכון התהליך</p>
        <InputText
          v-model="values.email"
          type="text"
          placeholder="דואל"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>יום לתיקון</p>
        <input v-model="values.fixDay" type="date" style="width:100%" />
      </div>
      <div class="field">
        <p>שעת כניסה</p>
        <input
          v-model="values.entranceTime"
          type="time"
          id="appt"
          name="appt"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>שעת יציאה</p>
        <input
          v-model="values.exitTime"
          type="time"
          id="appt"
          name="appt"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>סיבה לתיקון שעות</p>
        <Dropdown
          :options="reasons"
          v-model="values.reason"
          placeholder="סיבות לתיקון שעות"
          style="width:100%"
        />
      </div>
      <div class="field">
        <p>הערות</p>
        <Textarea
          v-model="values.comments"
          rows="5"
          cols="30"
          placeholder="הערות..."
          style="width:100%"
        />
      </div>
    </div>

    <Button
      label="שלח"
      class="p-button-help send-btn"
      @click="handleSubmit"
    />
  </div>

  <!-- JUST FOR MAIL -->
  <div class="form-email" id="form-email" v-show="false">
    <table
      style="width:50%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
    >
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          תאריך הבקשה
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.createdAt.toLocaleDateString("he") }},{{
            values.createdAt.toLocaleTimeString("he")
          }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding:8px;">
          שם הסניף השולח
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding:8px;">
          {{ values.branche }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          סוג הבקשה
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.type }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          שם העובד/ת
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.fullName }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          מס' ת"ז של העובד/ת
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.idNumber }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          שם פותח הבקשה
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.ownerName }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          אימייל לעדכון סטטוס וקבלת עדכונים
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.email }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          יום לתיקון
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ new Date(values.fixDay).toLocaleDateString("he") }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          שעת כניסה
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.entranceTime }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          שעת יציאה
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.exitTime }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          סיבה לתיקון שעות
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.reason }}
        </td>
      </tr>
      <tr>
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          סטטוס
        </th>
        <td
          style="border:1px solid #dddddd; text-align:right; padding: 8px; color:green"
        >
          {{ values.status }}
        </td>
      </tr>
      <tr style="background-color: #dddddd;">
        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          תיאור הבקשה
        </th>
        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
          {{ values.comments }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import swal from "sweetalert";
import {projectFirestore,projectFunctions} from '../../../../../firebase/config'
import {increment} from '@/Methods/office_request_funcs'
import { onMounted, ref } from "vue";
import store from '../../../../../store';
export default {
  props:['docId'],
  components: { InputText, Dropdown, Textarea },
  setup(props) {
    const error = ref("");
    const values = ref({
      fullName: "",
      idNumber: "",
      ownerName: "",
      email: "",
      fixDay: "",
      entranceTime: "",
      exitTime: "",
      reason: "",
      comments: "",
      branche: "",
      charge:"לא הוגדר",
      type: "תיקוני שעות",
      status: "חדש",
      createdAt: new Date()
    });

    const validation = () => {
      for (let key in values.value) {
        if (key == "fullName") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא שם מלא של העובד";
            return false;
          }
        }
        if (key == "idNumber") {
          if (values.value[key].length <7) {
            error.value = "נא למלא מספר זהות או דרכון תקין";
            return false;
          }
        }
        if (key == "ownerName") {
          if (values.value[key].length < 1) {
            error.value = "נא למלא את שם פותח הבקשה";
            return false;
          }
        }
        if (key == "email") {
          if (
            values.value[key].length == 0 ||
            !values.value[key].includes("@")
          ) {
            error.value = "נא למלא כתובת מייל תקינה";
            return false;
          }
        }
        if (key == "fixDay") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא יום לתיקון";
            return false;
          }
        }
        if (key == "entranceTime") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא שעת כניסה";
            return false;
          }
        }
        if (key == "exitTime") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא שעת יציאה";
            return false;
          }
        }
        if (key == "reason") {
          if (values.value[key].length == 0) {
            error.value = "נא למלא סיבה לתיקון שעות ";
            return false;
          }
        }
      }
      return true;
    };

    const handleSubmit = async () => {
      if (!validation()) {
        swal("בעיה", `${error.value}`, "warning");
      } else {
        const doc = projectFirestore.collection('Applications').doc(props.docId).collection('Hourly_corrections').doc()
        await doc.set({ ...values.value, id: doc.id });
        await increment(props.docId)
        sendEmailToUser();
        sendEmailToCharge();
        clearData();
        swal("הצלחה", "הטופס נשלח בהצלחה", "success");
      }
    };

    function sendEmailToUser() {
      projectFunctions.httpsCallable('send_email')({
        html: `<div>
                <h3>
                    שלום ${values.value.ownerName},
                </h3>
                <h3>
                    בקשתך המשרדית לעובד: ${values.value.fullName}
                </h3>
                <h3>
                    עבור: ${values.value.type}
                </h3>
                <h3>
                    לסניף/אתר ${values.value.branche}
                </h3>
                <h3>
                    נשלחה לטיפול!
                </h3>
                <h3 style="color:red;">
                    נא לשים לב להמשך עדכונים שיתקבלו אוטמטית מהמערכת בכל שלבי הטיפול!
                </h3>
            </div>`,
        destinations:[`${values.value.email}`],
        from:"מערכת לבקשות משרדיות",
        title:"נפתחה בקשה משרדית חדשה"
      }) 
    }

    function sendEmailToCharge() {
      projectFunctions.httpsCallable('send_email')({
        html: document.getElementById("form-email").innerHTML,
        destinations:["rosman.mate@gmail.com","ravit@rosman.co.il"],
        from:"מערכת לבקשות משרדיות",
        title:"נפתחה בקשה משרדית חדשה"
      })
    }

    const clearData = () => {
      const temp = {
        fullName: "",
        idNumber: "",
        ownerName: "",
        email: values.value.email,
        fixDay: "",
        entranceTime: "",
        exitTime: "",
        reason: "",
        comments: "",
        branche: values.value.branche,
        charge:"לא הוגדר",
        status: "חדש",
        type: "תיקוני שעות",

        createdAt: new Date()
      };
      values.value = temp;
    };

    onMounted(() => {
      setTimeout(() => {
        if(store.getters.user.branche){
          values.value.branche = store.getters.user.branche;
        }else{
          values.value.branche = store.getters.user.display_name;
        }
        values.value.email = store.getters.user.email
      }, 1000);
    });
    const reasons = ref([
      "עובד/ת שכח/ה להדפיס כרטיס",
      "כרטיס לא נקרא בקופה",
      "קופה לא פעילה",
      "הכנסת עובד/ת אחר/ת",
      "חריגת מכסת שעות תקן"
    ]);

    return { values, reasons, error, handleSubmit };
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.warpper {
  width: 50%;
  height: calc(100% - 100px);
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
.field {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
  margin-top: 15px;
}

.field p {
  font-size: 1.1rem;
}
.send-btn {
  margin-top: 5px;
  width: 50%;
}
input[type="date"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="date"]:hover {
  border: 1.1px solid lightblue;
}
input[type="date"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}
input[type="time"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="time"]:hover {
  border: 1.1px solid lightblue;
}
input[type="time"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}

@media screen and (max-width: 600px) {
 .container {
    justify-content: unset;
    overflow: hidden;
    background: var(--secondary);
    color: #fff;
  }
  .warpper {
    width: 100%;
    border: none;
    overflow-y: auto;
    flex-shrink: 0;
  }
  .send-btn {
    width: 100%;
  }
  .upload-btn {
    width: 100%;
  }
}
</style>
